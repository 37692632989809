<template>
    <div class="tips">
        {{$t('repeatTips')}}
    </div>
</template>
<script>
export default {
    data() {
        return {
        }
    },
    methods: {
    },
    mounted () {
    }
}
</script>
<style lang="less" scope>
body,html {
    width: 100%;
    height: 100%;
    background: var(--bodyColor);
}
.tips {
    width: 360px;
    margin: 20px auto;
    padding: 20px 20px;
    background: #fff;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
    border: 1px solid #ddd;
    text-align: center;
    box-sizing: border-box;
    line-height: 1rem;
    border-radius: 10px;
}
</style>